import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

import FullPageSection from '../components/FullPageSection'
import HeroTitle from '../components/HeroTitle'
import Footer from '../components/Footer'
import Header from '../components/Header'

import bgImage from '../img/tlc-issues.jpg'
import tlcImage from '../img/about.png'
import Script from 'react-load-script'

const IssuesPage = (props) => (
  <main>
    <Header className="bg-white" />

    <FullPageSection
      className="overlay-blue align-items-end align-items-lg-center d-flex splash"
      bgImage={bgImage}
    >
      <Container>
        <Row>
          <Col xs={12}>
            <HeroTitle
              className="mt-5 pt-5 pb-3"
              textArray={[
                'Together, we will end the carceral system,',
                'establish a care economy, and implement',
                'a Green New Deal for New York City.',
              ]}
            />
          </Col>
        </Row>
      </Container>
    </FullPageSection>
    <div className="mt-3 pt-5 pb-5 mb-5">
      <Container>
        <Row>
          <Col lg={{ span: 8, offset: 2 }}>
            <h2 className="mb-3">Our Vision</h2>

            <p>
              Read our comprehensive{' '}
              <strong>
                <a href="/public-safety">Public Saftey</a>
              </strong>{' '}
              policy page.
            </p>
            <p>
              <strong>
                What we are really paying police to do is meet protestors with
                war weapons, to uphold the school to prison pipeline, to help
                carry out evictions, to be ill-equipped first responders for
                mental health crises, and to conduct routine traffic stops.
              </strong>
            </p>
            <p>
              <strong>
                The billions of dollars could be redirected to the people and
                service providers best equipped to deal with those situations,
                and more important, prevent them from happening in the first
                place.
              </strong>
            </p>

            <p>
              Yet time and again, we’ve invested in the wrong things, and a lack
              of imagination and real political courage have stagnated New York
              City’s progress.
            </p>
            <p>
              As City Council Member, I will commit to divesting from carceral
              solutions and investing in what actually keeps communities safe
              and thriving. I will fight for the working people who have built
              this city. I will push policies that will not only protect New
              Yorkers now but will create a safer, more livable, city for
              decades to come. And I will co-govern every day alongside my
              constituents.
            </p>
            <p>To that end, I will work to:</p>

            <h3 className="color-base1 pt-4 pb-3 font-2">
              End the carceral system in New York City by defunding and
              disbanding the NYPD, permanently closing Rikers Island, halting
              the construction of new jails, and embedding restorative practices
              into all city agencies.
            </h3>

            <p>
              During my near-decade of work as a public defender in New York
              City, I witnessed the trauma our criminal legal system inflicts on
              Black and brown, poor, and immigrant communities every day. I have
              long fought for, and remain committed to, a complete
              transformation of our criminal legal system and an end to policing
              and mass incarceration.
            </p>

            <ul>
              <li>
                Defund, and ultimately disband, the NYPD and replace it with a
                community-led public safety infrastructure
              </li>
              <li>
                Fight to close Rikers Island before 2026 and halt the
                construction of the borough-based jails proposal
              </li>
              <li>
                Create a citywide bail vouchers program to facilitate the
                release of incarcerated individuals
              </li>
              <li>
                Remove police from schools, traffic enforcement, homeless
                outreach, and hospitals
              </li>
            </ul>

            <h3 className="color-base1 pt-4 pb-3 font-2">
              Invest in the things that keep communities safe by fully funding
              and desegregating our schools, building new social housing,
              protecting and expanding opportunities for small businesses, and
              ending transportation deserts.
            </h3>

            <p>
              I am the product of both public and private Queens schooling, a
              child of parents who grew up in Woodside Houses, and a proud
              supporter of the district’s diverse small businesses. As we fight
              to divest from policing and incarceration, we must prioritize
              investments in the resources and infrastructure that stabilizes
              individual lives and strengthens communities.
            </p>

            <ul>
              <li>
                Desegregate our schools by advocating for the repeal of
                Hect-Calandra at the state level, end all discriminatory screens
                in New York City public schools, and launch a community-led
                diversity plan for middle schools in Astoria
              </li>
              <li>
                Decommodify housing and support a city-level Homes Guarantee
                campaign by preserving existing affordable public and social
                housing (including NYCHA), building new social housing units,
                and advocating for a comprehensive plan
              </li>
              <li>
                Support small businesses and vendors by fighting for commercial
                rent control, lifting the cap on street vendor permits, and
                creating a permanent open street and lot program for business
                use
              </li>
              <li>
                Fight for increased federal and state funding for the MTA, and
                transition control over the subways to the city so that capital
                improvements are made to improve signals, expand service, and
                guarantee accessibility{' '}
              </li>
            </ul>

            <h3 className="color-base1 pt-4 pb-3 font-2">
              Establish a caring economy whereby the most vulnerable amongst us
              have the resources they need to truly thrive and the city’s
              workforce has dignity, adequate pay, and expanded protections.
            </h3>

            <p>
              I am the daughter of both a domestic worker who provided care to
              neighborhood children and a retired union worker with Otis
              Elevator Company. I know firsthand how important a strong and
              robust caregiving infrastructure is to the economic livelihood of
              our city, and how workplace protections, social benefits, and
              union membership can lift families out of poverty.
            </p>

            <ul>
              <li>
                Fight for universal access to affordable and quality childcare
                and eldercare and increase opportunities for training and
                financial assistance for primary caregivers
              </li>
              <li>
                Expand access to paid family leave under the city’s Earned Safe
                & Sick Leave Act to independent contractors and gig workers
              </li>
              <li>
                Explore the creation of a citywide portable benefits model to
                provide social benefits to workers regardless of employment
                status
              </li>
              <li>
                Implement just cause and whistleblower protections to combat
                unfair and discriminatory firings, which disproportionately harm
                Black and brown workers
              </li>
            </ul>

            <h3 className="color-base1 pt-4 pb-3 font-2">
              Implement a Green New Deal for New York City by creating green,
              union jobs, increasing public green spaces, making infrastructure
              more clean, and ensuring the city’s shorelines are resilient.
            </h3>

            <p>
              As Western Queens residents saw during Superstorm Sandy, New York
              City is particularly vulnerable to the ecological, social, and
              economic harms of climate change. I recognize the need for
              solutions as big as the crisis we face, and the importance of
              using a justice-oriented framework to transform our city and lift
              up communities most at-risk.
            </p>

            <ul>
              <li>
                Oppose any projects that expand the city’s fossil fuel
                infrastructure and advocate for the use of renewable energy
                sources, including solar and wind, to achieve a carbon-neutral
                New York City by 2030
              </li>
              <li>
                Guarantee healthy living for low-wage workers and communities of
                color by prioritizing them for new and high-paying clean energy
                jobs, fighting for publicly-owned power sources, prioritizing
                waste equity, and investing in urban farming
              </li>
              <li>
                Return streets to pedestrians, cyclists, and communities by
                creating permanent superblocks, investing in public plazas and
                greenspaces, and building more protected bike lanes and bus
                lanes
              </li>
              <li>
                Ensure the city’s shorelines are made more resilient through the
                creation of hardened, raised architecture and that waterfronts
                and waterways are publicly accessible and not hoarded by real
                estate interests
              </li>
            </ul>

            <h3 className="color-base1 pt-4 pb-3 font-2">
              Reimagine local government by bringing a proactive, relational
              organizing framework to constituent services, creating a socialist
              and leftist bloc to remake the City Council, and prioritizing
              collaborative design-making and co-governance.
            </h3>

            <p>
              In my work as a political organizer, I’ve helped support and elect
              radical change-agents committed to transforming local government
              institutions. The New City Council needs a radical shake-up. For
              the body to truly be the People’s home, we need to remake the
              institution from the inside-out, bring new life into City Hall,
              and repair decades of harm.{' '}
            </p>

            <ul>
              <li>
                Ensure constituent services is proactive, not reactive, and that
                district offices are hubs for organizing so that constituents
                can access the tools, skills, and training needed to find
                solutions to local problems
              </li>
              <li>
                Organize a socialist and leftist bloc within the City Council to
                influence not only the Speaker’s race, committee chairs and
                memberships, but the adoption of session rules and the budget
                process and outcome
              </li>
              <li>
                Implement internal rules reforms that limit the overwhelming
                power of the Speaker over the Council’s legislative and budget
                agenda and pushes the bounds of the body’s power as the
                effective counterweight to the Mayoralty
              </li>
              <li>
                Use working groups, resident councils and/or neighborhood block
                groups to influence decision-making, increase understanding of
                local government processes, and facilitate constant
                communication and co-governance
              </li>
            </ul>

            <h2 className="mt-4 mb-3">Our District</h2>

            <p>
              My platform is rooted in participatory engagement. The
              above-listed proposals are just a start and if you have
              recommendations, concerns, or specific proposals, please submit
              them below.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
    <Footer className="mt-5" />
  </main>
)

export default IssuesPage
