import React from 'react'
import { Link, graphql } from 'gatsby'

import PageLayout from '../layout/page'
import MetaSEO from '../components/MetaSEO'
import Issues from '../layout/IssuesPage';


class IssuesPage extends React.Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
  }

  formLoaded() {
  }

  render() {

    return (
      <PageLayout showBigCircle={false}>
        <MetaSEO title="Issues | Tiffany Cabán for Council District 22" keywords={[`tiffany cabán`, `tiffany caban`, `caban for queens`, `caban`, `cabán`, `queens district attorney`, `district attorney`]} 
          url="https://www.cabanforqueens.com/issues"
        />
        <Issues {...{...this.props.data.page}} />
      </PageLayout>
    );

  }
  
}

// <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
//   <Image />
// </div>
export default IssuesPage;

export const IssuesPageQuery = graphql`
  query issuesPageQuery {
    page: markdownRemark (frontmatter: { uniq: {eq: "about" }}) {
      html
      frontmatter {
        title
        subtitle
        spiel
      }
    }
  }

`;
